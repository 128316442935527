import * as styles from './PodcastContent.module.css'

import classNames from 'classnames'
import React, { ReactNode, useCallback, useState } from 'react'

interface Props {
  slug: string
  timestamps: ReactNode
  notes: ReactNode
  cardsMentioned: ReactNode
  annotationCredit?: {
    link: string | null
    name: string | null
  } | null
  initialView?: 'cards' | 'timestamps'
}

const PodcastContent: React.FC<Props> = (props) => {
  const { timestamps, notes, cardsMentioned, annotationCredit } = props

  const [activeView, setActiveView] = useState<null | 'timestamps' | 'cards'>(
    props.initialView ?? null,
  )

  // Update the state and the URL manually to prevent the player from
  // re-rendering and stopping.
  const navigateTo = useCallback(
    (view: null | 'timestamps' | 'cards') => {
      window.history.replaceState(
        {},
        '',
        `${window.location.protocol}//${window.location.host}/podcast/${
          props.slug
        }/${view != null ? `${view}/` : ''}`,
      )

      setActiveView(view)
    },
    [props.slug],
  )

  return (
    <div className={styles.container}>
      <nav role="navigation" className={styles.navigation}>
        <button
          className={classNames(styles.tab, {
            [styles.active]: activeView === null,
          })}
          onClick={() => {
            navigateTo(null)
          }}
        >
          Notes
        </button>
        <button
          className={classNames(styles.tab, {
            [styles.active]: activeView === 'timestamps',
          })}
          onClick={() => {
            navigateTo('timestamps')
          }}
        >
          Timestamps
        </button>
        <button
          disabled={cardsMentioned == null}
          className={classNames(styles.tab, {
            [styles.active]: activeView === 'cards',
          })}
          onClick={() => {
            navigateTo('cards')
          }}
        >
          Cards Mentioned
        </button>
      </nav>

      {activeView === null && <div>{notes}</div>}

      {activeView === 'timestamps' && (
        <div className={styles.content}>{timestamps}</div>
      )}

      {activeView === 'cards' && (
        <div className={styles.content}>{cardsMentioned}</div>
      )}

      {annotationCredit?.link && (
        <div className={styles.annotationCredit}>
          <div className={styles.annotationCreditContent}>
            Thank you to{' '}
            <a href={annotationCredit.link}>{annotationCredit.name}</a> for
            annotating this episode!
          </div>
        </div>
      )}
    </div>
  )
}

export default PodcastContent
